import { default as _36100_45ounceszhZMBYfdViMeta } from "/tmp/build_5385c194/pages/$100-ounces.vue?macro=true";
import { default as accessibilitymjmWWGxuzlMeta } from "/tmp/build_5385c194/pages/accessibility.vue?macro=true";
import { default as _91slug_93s9KqHrBTroMeta } from "/tmp/build_5385c194/pages/brands/[slug].vue?macro=true";
import { default as build_45a_45packJYPtOHrHdmMeta } from "/tmp/build_5385c194/pages/build-a-pack.vue?macro=true";
import { default as cannabis_45pricingM0feWEY8fcMeta } from "/tmp/build_5385c194/pages/cannabis-pricing.vue?macro=true";
import { default as checkoutkb9LjcbryeMeta } from "/tmp/build_5385c194/pages/checkout.vue?macro=true";
import { default as contactqEsqbijDysMeta } from "/tmp/build_5385c194/pages/contact.vue?macro=true";
import { default as confirmQvmDhG2winMeta } from "/tmp/build_5385c194/pages/dashboard/confirm.vue?macro=true";
import { default as index9G0QaT6A9YMeta } from "/tmp/build_5385c194/pages/dashboard/index.vue?macro=true";
import { default as _91id_93L65pFcRUAEMeta } from "/tmp/build_5385c194/pages/dashboard/jobs/[id].vue?macro=true";
import { default as createvnadVdHzccMeta } from "/tmp/build_5385c194/pages/dashboard/jobs/create.vue?macro=true";
import { default as indexZJhDzL9noEMeta } from "/tmp/build_5385c194/pages/dashboard/jobs/index.vue?macro=true";
import { default as loginzokLyFqrNKMeta } from "/tmp/build_5385c194/pages/dashboard/login.vue?macro=true";
import { default as bundle_45image_45builderc11w5j881EMeta } from "/tmp/build_5385c194/pages/dashboard/media/bundle-image-builder.vue?macro=true";
import { default as indexVKjC0IlkTPMeta } from "/tmp/build_5385c194/pages/dashboard/media/index.vue?macro=true";
import { default as indexCekGObO3dsMeta } from "/tmp/build_5385c194/pages/dashboard/menu/index.vue?macro=true";
import { default as _91_46_46_46slug_93QaUtRM4ILLMeta } from "/tmp/build_5385c194/pages/dashboard/pages/[...slug].vue?macro=true";
import { default as homecozBqXQxWOMeta } from "/tmp/build_5385c194/pages/dashboard/pages/home.vue?macro=true";
import { default as indexdJyxJF7QxzMeta } from "/tmp/build_5385c194/pages/dashboard/pages/index.vue?macro=true";
import { default as _91id_93YMJ2EtASwKMeta } from "/tmp/build_5385c194/pages/dashboard/products/[id].vue?macro=true";
import { default as indexh30spLNBWXMeta } from "/tmp/build_5385c194/pages/dashboard/products/index.vue?macro=true";
import { default as forecastsRZFrIuq6QnMeta } from "/tmp/build_5385c194/pages/dashboard/reporting/forecasts.vue?macro=true";
import { default as _91id_932vjIOE5hPIMeta } from "/tmp/build_5385c194/pages/dashboard/reviews/[id].vue?macro=true";
import { default as indexjftdB4J4b5Meta } from "/tmp/build_5385c194/pages/dashboard/reviews/index.vue?macro=true";
import { default as settingsY3glsDCneXMeta } from "/tmp/build_5385c194/pages/dashboard/settings.vue?macro=true";
import { default as theme0U93s4HwpnMeta } from "/tmp/build_5385c194/pages/dashboard/theme.vue?macro=true";
import { default as _91id_93ktl3Al9i89Meta } from "/tmp/build_5385c194/pages/dashboard/users/[id].vue?macro=true";
import { default as indexBV7XTOGyxhMeta } from "/tmp/build_5385c194/pages/dashboard/users/index.vue?macro=true";
import { default as indexqPcWwUs6P8Meta } from "/tmp/build_5385c194/pages/dashboard/web-vitals/index.vue?macro=true";
import { default as dashboardXXH8RasvAPMeta } from "/tmp/build_5385c194/pages/dashboard.vue?macro=true";
import { default as dispensary_45birthday_45deals_45michigansuXnzgBnqpMeta } from "/tmp/build_5385c194/pages/dispensary-birthday-deals-michigan.vue?macro=true";
import { default as dispensary_45dealsBSmtLqF5viMeta } from "/tmp/build_5385c194/pages/dispensary-deals.vue?macro=true";
import { default as first_45time_45dispensary_45dealsM2KEa1BLzYMeta } from "/tmp/build_5385c194/pages/first-time-dispensary-deals.vue?macro=true";
import { default as glass_45jarsIAIPQbtgxHMeta } from "/tmp/build_5385c194/pages/glass-jars.vue?macro=true";
import { default as grow_45facility_45tourSVxTIpFtKYMeta } from "/tmp/build_5385c194/pages/grow-facility-tour.vue?macro=true";
import { default as indexj1S2mS1ieUMeta } from "/tmp/build_5385c194/pages/index.vue?macro=true";
import { default as _91slug_93PywDh3Q7bxMeta } from "/tmp/build_5385c194/pages/jobs/[slug].vue?macro=true";
import { default as indexjeNSlpcbplMeta } from "/tmp/build_5385c194/pages/jobs/index.vue?macro=true";
import { default as cannabinoidsdO3bPjuapBMeta } from "/tmp/build_5385c194/pages/learn/cannabinoids.vue?macro=true";
import { default as cannabis_45anatomy4xgOdAeJL1Meta } from "/tmp/build_5385c194/pages/learn/cannabis-anatomy.vue?macro=true";
import { default as cannabis_45historyrP4PjWRQeAMeta } from "/tmp/build_5385c194/pages/learn/cannabis-history.vue?macro=true";
import { default as michigan_45dispensary_45purchase_45limitswgxsmNgT8hMeta } from "/tmp/build_5385c194/pages/learn/michigan-dispensary-purchase-limits.vue?macro=true";
import { default as terpenes_45over_45potencynjAujlk6OvMeta } from "/tmp/build_5385c194/pages/learn/terpenes-over-potency.vue?macro=true";
import { default as what_45is_45cannabis6CpcFT85HyMeta } from "/tmp/build_5385c194/pages/learn/what-is-cannabis.vue?macro=true";
import { default as media_45access31Sb2zY8jEMeta } from "/tmp/build_5385c194/pages/media-access.vue?macro=true";
import { default as michigan_45dispensary_45near_45indianar7NyOL8QxAMeta } from "/tmp/build_5385c194/pages/michigan-dispensary-near-indiana.vue?macro=true";
import { default as michigan_45dispensary_45near_45ohio3T2lm3lZYKMeta } from "/tmp/build_5385c194/pages/michigan-dispensary-near-ohio.vue?macro=true";
import { default as newsletteraXa0d7m4vXMeta } from "/tmp/build_5385c194/pages/newsletter.vue?macro=true";
import { default as order_45trackerz3tzFiNGRHMeta } from "/tmp/build_5385c194/pages/order-tracker.vue?macro=true";
import { default as privacy_45policytXe3dMZlbTMeta } from "/tmp/build_5385c194/pages/privacy-policy.vue?macro=true";
import { default as _91slug_93OT9KJB12WRMeta } from "/tmp/build_5385c194/pages/products/[slug].vue?macro=true";
import { default as quick_45order9ptNpu6GzrMeta } from "/tmp/build_5385c194/pages/quick-order.vue?macro=true";
import { default as return_45policy2mfqgpCwi8Meta } from "/tmp/build_5385c194/pages/return-policy.vue?macro=true";
import { default as reviewshcEd6e0SnOMeta } from "/tmp/build_5385c194/pages/reviews.vue?macro=true";
import { default as staging_45loginr6PQu6Ahc8Meta } from "/tmp/build_5385c194/pages/staging-login.vue?macro=true";
import { default as strain_45libraryATYWhBtsDfMeta } from "/tmp/build_5385c194/pages/strain-library.vue?macro=true";
import { default as terms_45of_45usetneCOmRO98Meta } from "/tmp/build_5385c194/pages/terms-of-use.vue?macro=true";
import { default as thank_45youXwBENmvh3hMeta } from "/tmp/build_5385c194/pages/thank-you.vue?macro=true";
import { default as weedys_45wearTGgCopqSUiMeta } from "/tmp/build_5385c194/pages/weedys-wear.vue?macro=true";
import { default as wholesaletXuLX6UT1OMeta } from "/tmp/build_5385c194/pages/wholesale.vue?macro=true";
export default [
  {
    name: "$100-ounces",
    path: "/$100-ounces",
    component: () => import("/tmp/build_5385c194/pages/$100-ounces.vue")
  },
  {
    name: "accessibility",
    path: "/accessibility",
    component: () => import("/tmp/build_5385c194/pages/accessibility.vue")
  },
  {
    name: "brands-slug",
    path: "/brands/:slug()",
    component: () => import("/tmp/build_5385c194/pages/brands/[slug].vue")
  },
  {
    name: "build-a-pack",
    path: "/build-a-pack",
    component: () => import("/tmp/build_5385c194/pages/build-a-pack.vue")
  },
  {
    name: "cannabis-pricing",
    path: "/cannabis-pricing",
    component: () => import("/tmp/build_5385c194/pages/cannabis-pricing.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_5385c194/pages/checkout.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/tmp/build_5385c194/pages/contact.vue")
  },
  {
    name: dashboardXXH8RasvAPMeta?.name,
    path: "/dashboard",
    meta: dashboardXXH8RasvAPMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard-confirm",
    path: "confirm",
    meta: confirmQvmDhG2winMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/confirm.vue")
  },
  {
    name: "dashboard",
    path: "",
    component: () => import("/tmp/build_5385c194/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-jobs-id",
    path: "jobs/:id()",
    component: () => import("/tmp/build_5385c194/pages/dashboard/jobs/[id].vue")
  },
  {
    name: "dashboard-jobs-create",
    path: "jobs/create",
    component: () => import("/tmp/build_5385c194/pages/dashboard/jobs/create.vue")
  },
  {
    name: "dashboard-jobs",
    path: "jobs",
    component: () => import("/tmp/build_5385c194/pages/dashboard/jobs/index.vue")
  },
  {
    name: "dashboard-login",
    path: "login",
    meta: loginzokLyFqrNKMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/login.vue")
  },
  {
    name: "dashboard-media-bundle-image-builder",
    path: "media/bundle-image-builder",
    meta: bundle_45image_45builderc11w5j881EMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/media/bundle-image-builder.vue")
  },
  {
    name: "dashboard-media",
    path: "media",
    meta: indexVKjC0IlkTPMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/media/index.vue")
  },
  {
    name: "dashboard-menu",
    path: "menu",
    component: () => import("/tmp/build_5385c194/pages/dashboard/menu/index.vue")
  },
  {
    name: "dashboard-pages-slug",
    path: "pages/:slug(.*)*",
    meta: _91_46_46_46slug_93QaUtRM4ILLMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/pages/[...slug].vue")
  },
  {
    name: "dashboard-pages-home",
    path: "pages/home",
    meta: homecozBqXQxWOMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/pages/home.vue")
  },
  {
    name: "dashboard-pages",
    path: "pages",
    meta: indexdJyxJF7QxzMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/pages/index.vue")
  },
  {
    name: "dashboard-products-id",
    path: "products/:id()",
    meta: _91id_93YMJ2EtASwKMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/products/[id].vue")
  },
  {
    name: "dashboard-products",
    path: "products",
    meta: indexh30spLNBWXMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/products/index.vue")
  },
  {
    name: "dashboard-reporting-forecasts",
    path: "reporting/forecasts",
    component: () => import("/tmp/build_5385c194/pages/dashboard/reporting/forecasts.vue")
  },
  {
    name: "dashboard-reviews-id",
    path: "reviews/:id()",
    meta: _91id_932vjIOE5hPIMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/reviews/[id].vue")
  },
  {
    name: "dashboard-reviews",
    path: "reviews",
    meta: indexjftdB4J4b5Meta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/reviews/index.vue")
  },
  {
    name: "dashboard-settings",
    path: "settings",
    meta: settingsY3glsDCneXMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-theme",
    path: "theme",
    meta: theme0U93s4HwpnMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/theme.vue")
  },
  {
    name: "dashboard-users-id",
    path: "users/:id()",
    meta: _91id_93ktl3Al9i89Meta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/users/[id].vue")
  },
  {
    name: "dashboard-users",
    path: "users",
    meta: indexBV7XTOGyxhMeta || {},
    component: () => import("/tmp/build_5385c194/pages/dashboard/users/index.vue")
  },
  {
    name: "dashboard-web-vitals",
    path: "web-vitals",
    component: () => import("/tmp/build_5385c194/pages/dashboard/web-vitals/index.vue")
  }
]
  },
  {
    name: "dispensary-birthday-deals-michigan",
    path: "/dispensary-birthday-deals-michigan",
    component: () => import("/tmp/build_5385c194/pages/dispensary-birthday-deals-michigan.vue")
  },
  {
    name: "dispensary-deals",
    path: "/dispensary-deals",
    component: () => import("/tmp/build_5385c194/pages/dispensary-deals.vue")
  },
  {
    name: "first-time-dispensary-deals",
    path: "/first-time-dispensary-deals",
    component: () => import("/tmp/build_5385c194/pages/first-time-dispensary-deals.vue")
  },
  {
    name: "glass-jars",
    path: "/glass-jars",
    component: () => import("/tmp/build_5385c194/pages/glass-jars.vue")
  },
  {
    name: "grow-facility-tour",
    path: "/grow-facility-tour",
    component: () => import("/tmp/build_5385c194/pages/grow-facility-tour.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/build_5385c194/pages/index.vue")
  },
  {
    name: "jobs-slug",
    path: "/jobs/:slug()",
    component: () => import("/tmp/build_5385c194/pages/jobs/[slug].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/tmp/build_5385c194/pages/jobs/index.vue")
  },
  {
    name: "learn-cannabinoids",
    path: "/learn/cannabinoids",
    component: () => import("/tmp/build_5385c194/pages/learn/cannabinoids.vue")
  },
  {
    name: "learn-cannabis-anatomy",
    path: "/learn/cannabis-anatomy",
    component: () => import("/tmp/build_5385c194/pages/learn/cannabis-anatomy.vue")
  },
  {
    name: "learn-cannabis-history",
    path: "/learn/cannabis-history",
    component: () => import("/tmp/build_5385c194/pages/learn/cannabis-history.vue")
  },
  {
    name: "learn-michigan-dispensary-purchase-limits",
    path: "/learn/michigan-dispensary-purchase-limits",
    component: () => import("/tmp/build_5385c194/pages/learn/michigan-dispensary-purchase-limits.vue")
  },
  {
    name: "learn-terpenes-over-potency",
    path: "/learn/terpenes-over-potency",
    component: () => import("/tmp/build_5385c194/pages/learn/terpenes-over-potency.vue")
  },
  {
    name: "learn-what-is-cannabis",
    path: "/learn/what-is-cannabis",
    component: () => import("/tmp/build_5385c194/pages/learn/what-is-cannabis.vue")
  },
  {
    name: "media-access",
    path: "/media-access",
    component: () => import("/tmp/build_5385c194/pages/media-access.vue")
  },
  {
    name: "michigan-dispensary-near-indiana",
    path: "/michigan-dispensary-near-indiana",
    component: () => import("/tmp/build_5385c194/pages/michigan-dispensary-near-indiana.vue")
  },
  {
    name: "michigan-dispensary-near-ohio",
    path: "/michigan-dispensary-near-ohio",
    component: () => import("/tmp/build_5385c194/pages/michigan-dispensary-near-ohio.vue")
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/tmp/build_5385c194/pages/newsletter.vue")
  },
  {
    name: "order-tracker",
    path: "/order-tracker",
    component: () => import("/tmp/build_5385c194/pages/order-tracker.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/tmp/build_5385c194/pages/privacy-policy.vue")
  },
  {
    name: "products-slug",
    path: "/products/:slug()",
    component: () => import("/tmp/build_5385c194/pages/products/[slug].vue")
  },
  {
    name: "quick-order",
    path: "/quick-order",
    component: () => import("/tmp/build_5385c194/pages/quick-order.vue")
  },
  {
    name: "return-policy",
    path: "/return-policy",
    component: () => import("/tmp/build_5385c194/pages/return-policy.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/tmp/build_5385c194/pages/reviews.vue")
  },
  {
    name: "staging-login",
    path: "/staging-login",
    meta: staging_45loginr6PQu6Ahc8Meta || {},
    component: () => import("/tmp/build_5385c194/pages/staging-login.vue")
  },
  {
    name: "strain-library",
    path: "/strain-library",
    component: () => import("/tmp/build_5385c194/pages/strain-library.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/tmp/build_5385c194/pages/terms-of-use.vue")
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/tmp/build_5385c194/pages/thank-you.vue")
  },
  {
    name: "weedys-wear",
    path: "/weedys-wear",
    component: () => import("/tmp/build_5385c194/pages/weedys-wear.vue")
  },
  {
    name: "wholesale",
    path: "/wholesale",
    component: () => import("/tmp/build_5385c194/pages/wholesale.vue")
  }
]